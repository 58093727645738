import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - La Peur" />
    <h3 className='underline-title'>La Peur</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Il se cache<br />
        <span className="par-starting"></span>s'enferme<br />
        <span className="par-starting"></span>s'emprissone<br />
      </p>
      <p>
        Il doit avoir peur <br />
        <span className="par-starting"></span>terriblement peur<br />
      </p>
      <p>
        Il a peur de tout<br />
        <span className="par-starting"></span>du vagissement d'un bébé<br />
        <span className="par-starting"></span>du vent léger qui souffle<br />
        <span className="par-starting"></span>du vol anodin d'un passereau<br />
      </p>
      <p>
        Il a peur <br />
        <span className="par-starting"></span>peur bleue<br />
        Il tremble de tout son corps<br />
      </p>
      <p>
        Il devrait être sans peur<br />
        Il a ses Benz-Jaguar<br />
        <span className="par-starting"></span>ses zaïres libératoires<br />
        <span className="par-starting"></span>ses chez-sois pimpants<br />
        <span className="par-starting"></span>son harem fourni<br />
      </p>
      <p>
        Il a pourtant peur<br />
        <span className="par-starting"></span>peur de fausse clef<br />
        <span className="par-starting"></span>peur de coupe-jarret<br />
        <span className="par-starting"></span>peur de petit frondeur<br />
      </p>
      <p>
        Il devrait être sans peur<br />
        Pourquoi a-t-il si peur?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
